import Vue from "vue"
import Products from '@/components/widgets/products'
import Branches from '@/components/widgets/branches'
import Documents from '@/components/widgets/documents'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
import moment from "moment"
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Products,
    Documents,
    Branches
  },
  data() {
    return {
      loading: false, 
      branchesOpend: false,
      productOpened: false,
      form: {
        license_plate: '',
        car_number: '',
        document_no: '',
        date: new Date(),
        type: 1,
        until: null,
        payment_terms: 1,
        note: '',
        branch_id: null,
        more_weight: ""
      },
      fields: [
        {
          key: "lot_no",
          label: "LOT No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "invoice_date",
          label: "Invoice date",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "coil",
          label: "COIL NO.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "can_pick",
          label: "สาขาสั่งได้",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "warehouse",
          label: "คลัง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "row",
          label: "แถว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาต่อกิโล/Kg",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "select_branch",
          label: "เลือกสาขา (วางบิล)",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "destination",
          label: "เลือกสาขา (รับของ)",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        {
          key: "action",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          class: "text-right text-xs-1/2",
        }
      ],
      branchesFields: [
        { 
          key: 'code', 
          label: 'รหัสสาขา',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2 py-1'
        },
        { 
          key: 'name', 
          label: 'ชื่อสาขา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 py-1',
          sortable: true 
        },
        { 
          key: 'address', 
          label: 'ที่อยู่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2 py-1' 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-right text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2 py-1'  
        }
      ],
      allBranches: [],
      branches: [],
      products: [],
      reqProducts: [],
      orderStatus: [
        { text: "รอเข้า", value: "awaiting" },
        { text: "พร้อมสั่ง", value: "readyfororder" },
        { text: "กำลังส่ง", value: "transferring" },
        { text: "รับแล้ว", value: "delivered" },
      ],
    }
  },
  methods: {
    getSelectedBranches(){
      return this.branches
    },
    getAllBraches(){
      return this.allBranches
    },
    onPurchaseOrderClose() {
      this.onreset()
      this.$router.push('/order-centric')
    },
    onselectProduct() {
      this.productOpened = !this.productOpened
    },
    onselectBranches() {
      this.branchesOpend = !this.branchesOpend
    },
    async onSubmitForm() {
      this.loading = true
      const formData = new FormData()


      if(this.branches.length === 0){
        this.onExceptionHandler("กรุณาเลือกสาขา")
        this.loading = false
        return
      }

      if(this.products.length === 0){
        this.onExceptionHandler("กรุณาเลือกม้วน")
        this.loading = false
        return
      }

      if(this.products.length === 0){
        this.onExceptionHandler("กรุณาเลือกม้วน")
        this.loading = false
        return
      }

      if(this.form.date === null){
        this.loading = false  
        this.onExceptionHandler("กรุณาเลือกวันที่")
        return
      }

      formData.append('document_no', this.form.document_no)
      formData.append('date', moment(this.form.date))
      formData.append('type', this.form.type)
      formData.append('until', this.form.until != null ? moment(this.form.until) : null)
      formData.append('payment_terms', this.form.payment_terms)
      formData.append('note', this.form.note)
      formData.append('license_plate', this.form.license_plate)
      formData.append('car_number', this.form.car_number)
      formData.append('more_weight', this.form.more_weight)
      
      this.form.branch_id = this.branches[0].id

      formData.append('branch_id', this.form.branch_id)
      formData.append('branches', JSON.stringify(this.branches))
      formData.append('products', JSON.stringify(this.products))

      try {
        await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/preemption`, formData)

        this.onreset()
        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => {
          this.$router.push('/order-centric')
        })
 
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    },
    onreset() {
      this.products = []
    },
    firstOrupdate(items) {
      if(this.products.length === 0) {
        this.products = items
      } else {
        for (let index = 0; index < items.length; index++) {
          const element = items[index]
          const item = _.find(this.products, { uuid: element.uuid })
          if (!item) {
            this.products.push(element)
          }
        }
      }
    },
    onRemoveBranches(id) {
      const index = _.findIndex(this.branches, function(o) { return o.uuid == id })
      this.branches.splice(index, 1)
    },
    onRemoveProduct(id) {
      const index = _.findIndex(this.products, function(o) { return o.uuid == id })
      this.products.splice(index, 1)
    },
    onInitProducts(event) {
      this.productOpened = false
      if (event.status) {
        this.firstOrupdate(event.data)
      }
    },
    onInitBranches(event) {
      this.branchesOpend = false
      if (event.status) {
        let items = event.data
        if(this.branches.length === 0) {
          this.branches.push(items)
        } else {
          const item = _.find(this.branches, { uuid: items.uuid })
          if (!item) {
            this.branches.push(items)
          }
        }
      }
    },
    async getBranches() {
      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.allBranches = result.data
    },
  },
  async created() {
    try {
      this.getBranches()

      const id = this.$route.params.id
      if(id) {
        const res = await this.$axios.get(this.$host + "/api/v1/document/request/" + id, {
          headers: {
          },
        })
        const data = res.data.products
        data.forEach(function(item){ 
          delete item.created_at
          delete item.updated_at
          delete item.request_document_id
          delete item.id
        });
        
        this.reqProducts = data
      }
    } catch (error) {
      this.loading = false
      this.onExceptionHandler(error.response.data.message)
    }
  }
}